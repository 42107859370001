import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import Header from '../components/header'
import Seo from '../components/seo'

class JobTemplate extends Component {

  render() {
    let page = this.props.data.wpJob

    return (
      <>
        <Seo title={he.decode(page.seo.title)} bodyClass={'single-job'} description={page.seo.metaDesc} />
        <Header />
        <section className='title-content title-content--job'>
          <div className='title-content__inner'>
            <div className='title-content__wrapper'>
              <div className='title-content__content' dangerouslySetInnerHTML={{ __html: page.content }} />
            </div>
          </div>
        </section>
      </>
    )
  }
}

export const jobQuery = graphql`
  query ($id: String!) {
    wpJob(id: {eq: $id}) {
      title
      content
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

export default JobTemplate
